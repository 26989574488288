import React from "react";
import { graphql } from "gatsby";
import { FaAngleDoubleRight } from "react-icons/fa";
import Title from "../components/Title";
import Seo from "../components/Seo";
import resume from "../assets/files/Giorgio_Federici_CV_EN_2022.pdf";

const About = ({ data }) => {
  const {
    allStrapiAbout: { nodes: about },
  } = data;
  const [value] = React.useState(0);
  const { title, image, info, stack } = about[value].data.attributes;
  const aboutImage = image.data.attributes.localFile.publicURL;
  console.log(image);
  return (
    <>
      <Seo title="About" />
      <section className="about-page">
        <div className="section-center about-center">
          <img src={aboutImage} alt={title} className="about-img-svg" />
          <article className="about-text">
            <Title title={title} />
            <p>{info}</p>
            {/* <div className="about-stack">
              {stack.map(item => {
                return <span key={item.id}>{item.title}</span>;
              })}
            </div> */}
          </article>
        </div>
      </section>
      <section className="section bg-black no-border py-2">
        <Title title="random notes" />
        <div className="jobs-center">
          <div className="btn-container"></div>
          <article className="job-info">
            {stack.map(item => {
              if (item.type !== "interests") {
                return;
              }
              return (
                <div key={item.id} className="job-desc">
                  <FaAngleDoubleRight className="job-icon"></FaAngleDoubleRight>
                  <p>{item.title}</p>
                </div>
              );
            })}
          </article>
        </div>
      </section>
      <section className="section bg-black no-border py-2">
        <Title title="software" />
        <div className="jobs-center">
          <div className="btn-container"></div>
          <article className="job-info">
            {stack.map(item => {
              if (item.type !== "software") {
                return;
              }
              return (
                <div key={item.id} className="job-desc">
                  <FaAngleDoubleRight className="job-icon"></FaAngleDoubleRight>
                  <p>{item.title}</p>
                </div>
              );
            })}
          </article>
        </div>
      </section>
      <section className="section bg-black no-border py-2">
        <Title title="hardware" />
        <div className="jobs-center">
          <div className="btn-container"></div>
          <article className="job-info">
            {stack.map(item => {
              if (item.type !== "hardware") {
                return;
              }
              return (
                <div key={item.id} className="job-desc">
                  <FaAngleDoubleRight className="job-icon"></FaAngleDoubleRight>
                  <p>{item.title}</p>
                </div>
              );
            })}
          </article>
        </div>
      </section>
      <section className="section bg-black no-border pt-2 pb-5">
        <Title title="misc" />
        <div className="jobs-center">
          <div className="btn-container"></div>
          <article className="job-info">
            {stack.map(item => {
              if (item.type !== "misc") {
                return;
              }
              return (
                <div key={item.id} className="job-desc stack-link">
                  <FaAngleDoubleRight className="job-icon"></FaAngleDoubleRight>
                  <a href={resume} download>
                    {item.title}
                  </a>
                  {` `}
                </div>
              );
            })}
          </article>
        </div>
      </section>
    </>
  );
};

export const query = graphql`
  {
    allStrapiAbout {
      nodes {
        data {
          attributes {
            title
            info
            stack {
              id
              title
              type
            }
            image {
              data {
                attributes {
                  localFile {
                    publicURL
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default About;
